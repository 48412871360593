.footer {
  margin-top: 10vh;
  padding: 0;
}

.copyright {
  margin-left: 1vw;
}

.tall-footer {
  padding: 1vw 3vh 1vw 1vh;
}
