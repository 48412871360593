.home .jumbo {
    font-size: large;
    color: #282c34;
}

.home .centered {
    justify-content: center;
    text-align: center;
}

.home .jumbo h1{
    font-size: 2.5rem;
}

.home .jumbo>div {
    padding: 0;
}

.jumbotron {
    background-color: rgba(255, 211, 55, 0.24);
    padding: 5vh;
    margin-top: 3vh;
}
