.row label {
    width: 100%;
}

.card {
    margin: 0.75rem 0;
}

.card-title {
    padding: 1.25rem 1.25rem 0;
}